.dashboard-main {
  position: absolute;
  top: 75px;
  left: 0px;
  background-color: #eeeeee;
  width: 94%;
  padding: 30px;
}

.newdashboard {
  width: 100%;
  height: auto;
  padding: 0px;

  .header {
    width: 100%;
    padding: 16px 24px;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    margin-bottom: 3px;
    margin-right: 0;
    margin-left: 0;
    background-color: white;

    .name {
      h1 {
        font-family: "Montserrat" !important;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: #757575;
        margin-bottom: 2px;
      }

      p {
        font-family: "Montserrat" !important;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #9e9e9e;
        margin-bottom: 5px;
      }
    }

    .notifications {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      h5 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #bdbdbd;
        margin: 0px 3px;
      }

      h6 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #424242;
        margin: 0px 20px 0px 5px;
      }

      p {
        font-family: "Roboto";
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 24px;
        color: #424242;
        margin: 0px 0px 0px 20px;
      }
    }
  }

  .central {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 24px;
    width: 100%;
    height: 60px;
    background: #ffffff;
    margin-bottom: 3px;

    .circle {
      width: 30px;
      height: 30px;
      border: 1px solid #9e9e9e;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 15px;
      margin-right: 24px;
      cursor: pointer;
    }

    h4 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #424242;
      margin-bottom: 0px;
    }
  }

  .central-body {
    // display: flex;
    // flex-direction: row;
    // justify-content: space-between;
    // align-items: center;
    padding: 24px;
    width: 100%;
    height: auto;
    background: #ffffff;
    margin-bottom: 3px;

    .box {
      width: 33%;
      height: 240px;
      padding: 10px;
      background: #f5f5f5;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      position: relative;

      h5 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        text-transform: uppercase;
      }

      h6 {
        position: absolute;
        top: 10px;
        right: 10px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        text-transform: uppercase;
        color: #757575;
      }

      .caixa {
        padding: 20px;
      }
    }

    .box-notes {
      width: 33%;
      height: 240px;
      padding: 0px;
      background: #f5f5f5;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      position: relative;

      h5 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        text-transform: uppercase;
        margin: 10px 0px 10px 10px;
      }

      h6 {
        position: absolute;
        top: 10px;
        right: 10px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        text-transform: uppercase;
        color: #757575;
      }

      .notes {
        background-color: white;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        overflow: scroll;

        .note-blue {
          margin: 3px 0px 0px 0px;
          padding: 13px 10px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
          background-color: #e1f5fe;
          width: 100%;
          height: auto;

          .left {
            width: 60%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;

            .circle-red {
              width: 30px;
              height: 30px;
              border-radius: 15px;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #ffebee;
              margin-right: 15px;
            }

            h3 {
              font-family: "Montserrat";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 17px;
              color: #424242;
              margin-bottom: 3px;
            }

            h6 {
              font-family: "Montserrat";
              font-style: normal;
              font-weight: normal;
              font-size: 10px;
              line-height: 12px;
              color: #9e9e9e;
              margin-bottom: 0px;
              position: relative;
              top: 0px;
              right: 0px;
            }

            .circle-roxo {
              width: 30px;
              height: 30px;
              border-radius: 15px;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #ede7f6;
              margin-right: 15px;
            }

            .circle-amarelo {
              width: 30px;
              height: 30px;
              border-radius: 15px;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #ffecb3;
              margin-right: 15px;
            }
          }

          .data {
            h5 {
              font-family: Montserrat;
              font-style: normal;
              font-weight: 500;
              font-size: 10px;
              line-height: 12px;
              color: #9e9e9e;
              margin: 0px;
            }
          }
        }

        .note-blue:hover {
          background-color: white;
        }

        .note-cinza:hover {
          background-color: white;
        }

        .note-cinza {
          margin: 3px 0px 0px 0px;
          padding: 13px 10px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
          background-color: #f5f5f5;
          width: 100%;
          height: auto;

          .left {
            width: 60%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;

            .circle-red {
              width: 30px;
              height: 30px;
              border-radius: 15px;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #ffebee;
              margin-right: 15px;
            }

            h3 {
              font-family: "Montserrat";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 17px;
              color: #424242;
              margin-bottom: 3px;
            }

            h6 {
              font-family: "Montserrat";
              font-style: normal;
              font-weight: normal;
              font-size: 10px;
              line-height: 12px;
              color: #9e9e9e;
              margin-bottom: 0px;
              position: relative;
              top: 0px;
              right: 0px;
            }

            .circle-roxo {
              width: 30px;
              height: 30px;
              border-radius: 15px;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #ede7f6;
              margin-right: 15px;
            }

            .circle-amarelo {
              width: 30px;
              height: 30px;
              border-radius: 15px;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #ffecb3;
              margin-right: 15px;
            }
          }

          .data {
            h5 {
              font-family: Montserrat;
              font-style: normal;
              font-weight: 500;
              font-size: 10px;
              line-height: 12px;
              color: #9e9e9e;
              margin: 0px;
            }
          }
        }
      }
    }
  }

  .academy-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 24px;
    width: 100%;
    height: auto;
    background: #ffffff;
    margin-bottom: 3px;

    .boxes {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 20px;

      .box-blue {
        background-color: #3f51b5;
        width: 200px;
        height: 108px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        border-radius: 4px;

        h5 {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #ffffff;
          margin-bottom: 0px;
        }

        h3 {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 29px;
          color: #ffffff;
          margin-bottom: 0px;
        }

        h6 {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          color: #ffffff;
          margin-bottom: 0px;
        }
      }

      .box-cyan {
        background-color: #2196f3;
        width: 200px;
        height: 108px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        border-radius: 4px;

        h5 {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 17px;
          color: #ffffff;
          margin-bottom: 0px;
        }

        h3 {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 29px;
          color: #ffffff;
          margin-bottom: 0px;
        }

        h6 {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          color: #ffffff;
          margin-bottom: 0px;
        }
      }

      .box-agua {
        background-color: #00bcd4;
        width: 200px;
        height: 108px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 16px;
        border-radius: 4px;

        h5 {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #ffffff;
          margin-bottom: 10px;
        }

        h3 {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          font-size: 36px;
          line-height: 29px;
          color: #ffffff;
          margin-bottom: 0px;
        }
      }

      .box-green {
        background-color: #009688;
        width: 200px;
        height: 108px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 16px;
        border-radius: 4px;

        h5 {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #ffffff;
          margin-bottom: 10px;
        }

        h3 {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          font-size: 36px;
          line-height: 29px;
          color: #ffffff;
          margin-bottom: 0px;
        }
      }

      .box-green-ligth {
        background-color: #8bc34a;
        width: 200px;
        height: 108px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 16px;
        border-radius: 4px;

        h5 {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #ffffff;
          margin-bottom: 10px;
        }

        h3 {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          font-size: 36px;
          line-height: 29px;
          color: #ffffff;
          margin-bottom: 0px;
        }
      }
    }

    .info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .box {
        width: 49%;
        height: 335px;
        padding: 10px;
        background: #f5f5f5;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;

        h5 {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          text-transform: uppercase;
          color: #424242;
        }

        h6 {
          position: absolute;
          top: 10px;
          right: 10px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 10px;
          line-height: 12px;
          text-transform: uppercase;
          color: #757575;
        }

        .caixa {
          padding: 20px;
        }

        .dots {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          margin: 20px 10px 10px 0px;
          width: 100%;

          h5#green {
            font-family: "Montserrat" !important;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 15px;
            color: #8bc34a;
            display: inline-block;
            margin-right: 15px;

            .dot-green {
              background-color: #8bc34a;
              width: 8px;
              height: 8px;
              border-radius: 4px;
              margin-right: 5px;
              display: inline-block;
            }

            h5 {
              color: #757575;
              display: inline-block;
            }
          }

          h5#orange {
            font-family: "Montserrat" !important;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 15px;
            color: #ff9800;
            display: inline-block;
            margin-right: 15px;

            .dot-orange {
              background-color: #ff9800;
              width: 8px;
              height: 8px;
              border-radius: 4px;
              margin-right: 5px;
              display: inline-block;
            }

            h5 {
              color: #757575;
              display: inline-block;
            }
          }

          h5#red {
            font-family: "Montserrat" !important;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 15px;
            color: #f44336;
            display: inline-block;
            margin-right: 15px;

            .dot-red {
              background-color: #f44336;
              width: 8px;
              height: 8px;
              border-radius: 4px;
              margin-right: 5px;
              display: inline-block;
            }

            h5 {
              color: #757575;
              display: inline-block;
            }
          }
        }
      }
    }
  }

  .financeiro-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 24px;
    width: 100%;
    height: auto;
    background: #ffffff;
    margin-bottom: 3px;

    .boxes {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 20px;

      .box-green {
        background-color: #4caf50;
        width: 250px;
        height: 108px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 16px;
        border-radius: 4px;

        h5 {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #ffffff;
          margin-bottom: 10px;
        }

        h3 {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          font-size: 36px;
          line-height: 29px;
          color: #ffffff;
          margin-bottom: 0px;
        }
      }

      .box-roxo {
        background-color: #7e57c2;
        width: 250px;
        height: 108px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 16px;
        border-radius: 4px;

        h5 {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #ffffff;
          margin-bottom: 10px;
        }

        h3 {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          font-size: 36px;
          line-height: 29px;
          color: #ffffff;
          margin-bottom: 0px;
        }
      }

      .box-orange {
        background-color: #ffa726;
        width: 250px;
        height: 108px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 16px;
        border-radius: 4px;

        h5 {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #ffffff;
          margin-bottom: 10px;
        }

        h3 {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          font-size: 36px;
          line-height: 29px;
          color: #ffffff;
          margin-bottom: 0px;
        }
      }

      .box-red {
        background-color: #ff5722;
        width: 250px;
        height: 108px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 16px;
        border-radius: 4px;

        h5 {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #ffffff;
          margin-bottom: 10px;
        }

        h3 {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          font-size: 36px;
          line-height: 29px;
          color: #ffffff;
          margin-bottom: 0px;
        }
      }
    }

    .grafico {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      height: auto;
      width: 100%;
      padding: 8px;

      h5 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        text-transform: uppercase;
        color: #424242;
        margin-bottom: 0px;
      }

      .caixa {
        padding: 20px;
      }
    }
  }
}
