@import "variables.scss";
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";
@import "core/palette.scss";
@import "app-variables.scss";
@import "mixins.scss";
@import "custom-bootstrap.scss";
@import "core.scss";
@import "plugins.scss";
@import "customizer.scss";
@import "demo.scss"; // Remove this SCSS from production
@import "_fonts.scss";
@import "_login.scss";
@import "_newdashboard";
@import "_newdetails";

.card-title {
  font-size: 18px;
  color: #2b2b2b;
  font-family: $poppins;
  font-weight: 700;
}

.font-medium-1 {
  font-family: $poppins-semibold;
}

.font-small-3 {
  font-family: $poppins-regular;
  font-size: 0.8125rem !important;
}

.text-muted {
  color: $waterloo !important;
}

.form-check-label {
  font-family: $poppins-regular;
  color: $waterloo;
  font-size: 12px;
}

.b-bottom {
  border-bottom: 1px solid #f1f3fa;
}

body {
  background-color: white !important;
}

.master-kpi {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 6px;

  span {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 14px;
  }
}

.master-kpi.purple {
  background-color: #6d45d9;
}

.master-kpi.blue {
  background-color: #0081ff;
}

.danger {
  fill: #ff586b !important;
}

.btn-outline-primary {
  color: #2f80ed !important;
}

.btn-outline-primary:hover {
  background-color: #2f80ed !important;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus .btn:focus {
  box-shadow: none;
}

.no-border {
  border: 0px !important;
}

.bg-white {
  background-color: white !important;
}

.badge-warning {
  background-color: #fb8115 !important;
  color: white !important;
}

.rdt_TableHeader {
  min-height: 0px !important;
}

.SCTable {
  font-family: "Poppins";

  .btn-sm {
    padding: 3px !important;
    margin-left: 8px;
    margin-bottom: 0px;
  }

  .btn-action-sctable {
    width: 30px;
    height: 30px;
  }
}
