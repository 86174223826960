.login-layout {
  position: absolute !important;
  top: 0 !important;
  height: 100% !important;
  width: 100% !important;
}

.login {
  height: 100%;

  margin: 0;

  .login-bg {
    height: 100vh;
    padding-left: 500px;
    padding-right: 180px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .login-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .login-form-card {
      width: 540px;
      padding: 40px 50px 1px 50px;
      background-color: white;
      border-radius: 30px;

      .sua-logo {
        height: 120px;
        display: block;
        margin: 0 auto;
      }

      a {
        color: #959595;
      }

      h4 {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        color: #1a1a1a;
        margin-top: 30px;
      }

      label {
        font-family: "Roboto";
        display: block;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #363636;
      }

      label.c-checkbox {
        display: flex;
        align-items: center;
        text-transform: unset;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 21px;
        color: #959595;

        margin-top: 10px;

        display: flex;
        align-self: center;

        input {
          margin-right: 8px;
          //margin-top: 2px;
          width: 16px;
          height: 16px;
        }
      }

      input[type="text"],
      input[type="password"] {
        width: 100%;
        height: 50px;
        background-color: white;
        border: 1px solid rgba(0, 0, 0, 0.38);
        box-sizing: border-box;
        border-radius: 8px;
        padding: 0px 16px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
      }

      button {
        width: 100%;
        height: 59px;
        background: #007aff;
        border-radius: 8px;
        border: 0px;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 19px;
        color: white;
        margin-top: 35px;
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        -webkit-box-shadow: 0 0 0 30px white inset !important;
      }
    }
  }

  @media (min-width: 200px) and (max-width: 1024px) {
    .login-content {
      display: flex;
      flex-direction: column;
      justify-content: unset !important;
    }

    .login-bg {
      padding: 0px 16px !important;
      height: 101% !important;
      position: absolute;
      width: 100%;
      top: 0;
      align-items: unset !important;
    }

    .login-form-card {
      width: 100% !important;
      padding: 16px 16px 1px 16px !important;
      border-radius: 16px !important;
      margin-bottom: 20px !important;

      label {
        font-size: 10px !important;
      }

      label.c-checkbox {
        font-size: 11px !important;
      }

      .sua-logo {
        height: 40px !important;
      }

      h4 {
        font-size: 18px !important;
        margin-top: 8px !important;
      }

      button {
        width: 100% !important;
        height: 40px !important;
        font-size: 14px !important;
        margin-top: 20px !important;
      }
    }

    .logo-sc {
      height: 120px;
      margin: 27px 0px;
    }
  }

  @media (min-width: 1025px) and (max-width: 1440px) {
    .login-bg {
      padding-left: 347px !important;
      padding-right: 53px !important;
    }
  }
}
