.customizer {
  width: 400px;
  right: -400px;
  padding: 0;
  background-color: #fff;
  z-index: 1051;
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100vh;
  transition: right 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  backface-visibility: hidden;
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);

  &.open {
    right: 0;
  }

  .customizer-content {
    position: relative;
    height: 100%;
  }

  a.customizer-toggle {
    background: #fff;
    color: theme-color("primary");
    display: block;
    box-shadow: -3px 0px 8px rgba(0, 0, 0, 0.1);
  }
  a.customizer-close {
    color: #000;
  }
  .customizer-close {
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 7px;
    width: auto;
    z-index: 10;
  }
  #rtl-icon {
    position: absolute;
    right: -1px;
    top: 35%;
    width: 54px;
    height: 50px;
    text-align: center;
    cursor: pointer;
    line-height: 50px;
    margin-top: 50px;
  }
  .customizer-toggle {
    position: absolute;
    top: 85%;
    width: 54px;
    height: 50px;
    left: -54px;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
  }
  .color-options {
    a {
      white-space: pre;
    }
  }

  .cz-bg-color {
    margin: 0 auto;
    span {
      &:hover {
        cursor: pointer;
      }
      &.white {
        color: #ddd !important;
      }
    }
    .selected {
      border: 3px solid #314fe5;
    }
  }

  .cz-bg-image {
    &:hover {
      cursor: pointer;
    }
    img.rounded {
      border-radius: 1rem !important;
      border: 2px solid #e6e6e6;
      margin-right: 8px;
      &.selected {
        border: 2px solid #ff586b;
      }
    }
  }
}
