.details-main{
  position: absolute;
  top: 75px;
  left: 0px;
  background-color: #EEEEEE;
  width: calc(100vw - 250px);
  padding: 30px;
}

.newdetails{
  width: 100%;
  height: auto;
  padding: 0px;
  
  .header{
    width: 100%;
    padding: 16px 24px;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    margin-bottom: 3px;
    background-color: white;

    .name{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      img{
        margin-right: 20px;
      }

      h1{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;
        text-transform: uppercase;
        color: #424242;
        margin-bottom: 0px;
      }
    }

    .notifications{
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      h1{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;
        text-transform: uppercase;
        color: #424242;
        margin-bottom: 0px; 
      }

      img{
        margin: 0px 10px;
      }

      h3{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        text-transform: uppercase;  
        margin-bottom: 0px;
      }

      h3#green{
        color:#9CCC65;
      }

      #orange{
        color:#FBC02D;
      }

      #red{
        color:#D32F2F;
      }

      .caixa-button{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 0px 10px 20px;
        margin-left: 20px;

        button{
          background-color: #1890FF;
          color:white;
          border:none;
          padding: 8px 16px;
          border-radius: 2px;
          cursor: pointer;
        }
      }
    }
  }

  .aluno{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 24px;
    width: 100%;
    height: 60px;
    background: #FFFFFF;
    margin-bottom: 3px;

    .circle{
      width: 30px;
      height: 30px;
      border: 1px solid #9E9E9E;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 15px;
      margin-right: 24px;
      cursor: pointer;
    }

    h4{
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #424242;
      margin-bottom: 0px;
    }
  }

  .aluno-body{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 24px;
    width: 100%;
    height: auto;
    background: #FFFFFF;
    margin-bottom: 3px;

    .box{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 32.5%;
      margin-right: 8px;
      margin-bottom: 10px;

      h3{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        text-transform: uppercase;
        color: #000000;  
        margin-bottom: 0px;
        width: 30%;
      }

      h5{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        color: #757575;
        margin-bottom: 0px;
        width: 70%;
      }
    }
  }

  .menu-tabs{
    background-color: white;
    width: 100%;
    height: auto;
    margin-bottom: 3px;

    .row-menu{
      border-bottom: 0px !important;

      .item-menu{
        background-color: white;

        h4{
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          margin-bottom: 0px; 
        }
      }
    }
  }

  .item-tab{
    width: 100%;
    background-color: white;

    .matricula-row{
      margin-top:0px;

      .boxes{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 15px;
        padding-top: 20px;

        .box{
          width: 48%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          height: auto;
          border-radius: 4px;

          .head{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 16px 24px;
            width: 100%;
            height: 55px;
            background: #F5F5F5;
            border-radius: 4px 4px 0px 0px; 
            margin-bottom: 3px;

            h3{
              font-family: "Montserrat";
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 20px;
              color: #424242;
              margin-bottom: 0px;
              width: 60%;
            }

            h4{
              font-family: "Montserrat";
              font-style: normal;
              font-weight: bold;
              font-size: 12px;
              line-height: 15px;
              text-transform: uppercase;
              color: #9E9E9E; 
              margin-bottom: 0px; 
            }

            h5{
              font-family:"Montserrat";
              font-style: normal;
              font-weight: 600;
              font-size: 10px;
              line-height: 12px;
              text-transform: uppercase;
              color: #1E88E5;  
              margin-bottom: 0px;
            }
          }

          .body-box{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 16px;
            width: 100%;
            height: auto;
            background: #F5F5F5;  

            h3{
              font-family: Montserrat;
              font-style: normal;
              font-weight: bold;
              font-size: 12px;
              line-height: 15px;
              text-align: center;
              text-transform: uppercase;
              color: #757575;
            }

            .white-box{
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: flex-start;
              width: 100%;

              .box-text{
                width: 23%;

                .box{
                  background-color: white;
                  display: flex;
                  flex-direction: row;
                  width: 100%;
                  justify-content: center;
                  align-items: center;
                  border-radius: 2px;
                  padding: 14px 8px;
                  position: relative;
                  margin-bottom: 10px;
                  font-family: "Montserrat";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 12px;
                  line-height: 15px;
                  text-transform: uppercase;
                  color: #9E9E9E;

                  h5{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-family: "Montserrat";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 15px;
                    text-transform: uppercase;
                    color: #000000;
                    margin-bottom: 0px;
                    width: 50%;
                  }

                  a{
                    text-decoration-line: underline;
                    color: #2979FF;
                  }
                }

                h3{
                  font-family:" Montserrat";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 12px;
                  line-height: 16px;
                  text-align: center;
                  color: #757575;
                  margin-bottom: 0px;
                }
              }
            }
          }
        }
      }

      .title{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        border-top: 1px solid #E0E0E0;
        padding-top: 20px;

        h3{
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          text-transform: uppercase;
          color: #424242; 
          margin-bottom: 0px;
        }

        .risco{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          h5{
            font-family: Montserrat;
            font-style: normal;
            font-weight: bold;
            font-size: 10px;
            line-height: 12px;
            text-transform: uppercase;
            color: #424242; 
            margin-bottom: 0px; 
            margin-right: 15px;
          }
        }
      }

      .header{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: flex-start;
        padding: 12px 0px 0px 0px;
        width: 100%;
        height: auto;
        background: #FFFFFF;
        margin-bottom: 3px;

        .box{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 32.5%;
          margin-right: 8px;
          margin-bottom: 10px;
    
          h3{
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            text-transform: uppercase;
            color: #000000;  
            margin-bottom: 0px;
            width: 30%;
          }
    
          h5{
            font-family: "Montserrat";
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 15px;
            color: #757575;
            margin-bottom: 0px;
            width: 70%;
          }
        }
      }

      .tabela{
        width: 100%;
        height: auto;
        padding: 16px 0px;
        border-top: 1px solid #E0E0E0;
        margin-top:10px;

        .head{
          padding: 0px 16px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          li{
            font-family: "Montserrat";
            font-style: normal;
            font-weight: bold;
            font-size: 10px;
            line-height: 12px;
            text-transform: uppercase;
            color: #757575;  
          }
        }

        .items{
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;

          .item{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            background-color: white;
            padding: 20px 16px;
            margin: 0px 0px;
            max-height: 60px;

            h3{
              font-family: Montserrat;
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 15px;
              text-transform: uppercase;
              color: #000000;  
              margin-bottom: 0px;
            }

            h4{
              font-family: "Montserrat" !important;
              font-style: normal;
              font-weight: bold;
              font-size: 10px;
              line-height: 12px;
              display: inline-block;
              margin-bottom: 0px;
            }

            p{
              font-family: "Montserrat" !important;
              font-style: normal;
              font-weight: normal;
              font-size: 10px;
              line-height: 12px;
              color: #757575;  
              display: inline-block;
              margin-bottom: 0px;
              margin-left: 5px;
            }

            h6{
              font-family: Montserrat;
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 15px;
              text-transform: uppercase;
              color: #424242; 
              margin-bottom: 0px;
            }
          }

          .item:hover{
            background-color: #F5F5F5;
          }
        }
      }

      .graficos{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;

        h3{
          font-family: "Montserrat";
          font-style: normal;
          font-weight: bold;
          font-size: 10px;
          line-height: 12px;
          text-transform: uppercase;
          color: #424242;  
          margin-bottom: 0px;
        }

        .box-graficos{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 100%; 
          margin-top:20px; 
        }
      }
    }
  }
}

.nav-tabs .nav-link {
  border: 0px !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  padding: 20px !important;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
  color:#245FAD !important;
  border-bottom: 1px solid #245FAD !important;
}

.nav-tabs .nav-link, .nav-tabs .nav-item.show .nav-link{
  color: #BDBDBD !important;
}

.tag-azul{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  width: 104px;
  height: 20px;
  background: #90CAF9;
  border-radius: 32px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #1E88E5;
}

.tag-green{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  width: 104px;
  height: 20px;
  background: #deeecc;
  border-radius: 32px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #6a9737;
}

.tag-yellow{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  width: 104px;
  height: 20px;
  background: yellow;
  border-radius: 32px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #97820a;
}

.tag-red{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  width: 104px;
  height: 20px;
  background: #FFCCBC;
  border-radius: 32px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #FF7043;
}
.tag-orange {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  width: 104px;
  height: 20px;
  background: orange;
  border-radius: 32px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #FF7043;
}

#fondo-cinza{
  background-color: #F5F5F5;
}

h4#text-grafico{
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  text-transform: uppercase;
  color: #757575;  
  margin-bottom: 0px;
}

h4#green, h5#green{
  color: #8BC34A;
}

h4#orange{
  color: #F9A825;
}

h4#red, h5#red{
  color: #FF1744;
}

h6#blue, h3#azul{
  color:#42A5F5;
}

h6#red{
  color:#FF1744;
}

h3#black{
  color:#000000;
}