$font-path: "assets/fonts" !default;

@font-face {
  src: url("#{$font-path}/Poppins/Poppins-Bold.ttf") format("truefont");
  font-family: "Poppins Bold";
  font-weight: 700;
}

@font-face {
  src: url("#{$font-path}/Poppins/Poppins-Medium.ttf") format("truefont");
  font-family: "Poppins Medium";
}

@font-face {
  src: url("#{$font-path}/Poppins/Poppins-SemiBold.ttf") format("truefont");
  font-family: "Poppins SemiBold";
}

@font-face {
  src: url("#{$font-path}/Poppins/Poppins-Regular.ttf") format("truefont");
  font-family: "Poppins Regular";
}

.roboto-bold {
  font-family: "Roboto";
  font-weight: 700;
}

.roboto-medium {
  font-family: "Roboto";
  font-weight: 500;
}

.btn {
  font-family: "Roboto";
}

@font-face {
  font-family: "Montserrat";
  src: url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
}