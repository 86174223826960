$branco: #fff !important;
.badge {
  background-color: #0cc27e;
  color: #fff;
}
.login-layout {
  background-color: #fff;
  // background-image: linear-gradient(to right, #21e4ff, #2f80ed);
}

//LIGHT
.body-light {
  background-color: white !important;
  height: 100%;
  .combo-select__menu{
    z-index: 10000;
  }
  .combo-select {
    > div {
      box-shadow: 0 4px 1px 0 rgba(212, 212, 212, 0.5) !important;
      border: 1px solid rgba(53, 63, 81, 0.3) !important;
      
      // :focus{
      //     border: 1px solid rgba(53, 63, 81, 0.3) !important;
      //     box-shadow: 0 4px 1px rgba(53, 63, 81, 0.3) !important;
      //     outline: none;
      // }
    }
  }

  //navbar
  .icon-navbar {
    stroke: #474747 !important;
  }

  //card
  & .card-table,
  & .card-ticket {
    border: 1px solid #e0e0e0 !important;
    background: $branco;

    & > .card-body {
      & > div:nth-child(2) {
        margin-bottom: 0px !important;
      }
    }

    & > .card-header {
      padding: 32px 32px 0px 32px !important;

      & > .card-text {
        color: #000;
      }
    }

    & > .card-footer {
      background: $branco;
      border: none;
    }

    .card-title,
    .card-title-pesquisas,
    .card-title-contextos {
      color: #333 !important;
    }

    & .div-questoes {
      & > .btn-questoes {
        background: none;
        &:focus,
        &:active,
        &:hover,
        &.foco-card-questoes {
          & > svg#multipla {
            box-shadow: 0 0 10px 0 rgba(73, 149, 242, 0.2);
            & > rect {
              stroke: #4995f2;
              fill: #4995f2;

              &:nth-child(1) {
                fill: #fff;
              }
            }
            & > circle {
              fill: #4995f2;
              stroke: #4995f2;
            }
          }

          & > svg#classificacao {
            box-shadow: 0 0 10px 0 rgba(73, 149, 242, 0.2);
            & > rect {
              stroke: #4995f2;
              fill: #4995f2;

              &:nth-child(1) {
                fill: #fff;
              }
            }
            & > path {
              fill: #4995f2;
              stroke: #4995f2;
            }
          }

          & > svg#descritiva {
            box-shadow: 0 0 10px 0 rgba(73, 149, 242, 0.2);
            & > rect {
              // stroke: #4995f2;
              fill: #4995f2;

              &:nth-child(1) {
                fill: #fff;
                stroke: #4995f2;
              }
            }
            & > path {
              fill: #4995f2;
              // stroke: #4995f2;
            }
          }
        }
        & > p {
          color: #696969;
        }
      }
    }
  }

  & .card-ticket {
    > p,
    > ul > li {
      // color: #535B6A;
      color: #1c325a;

      > span.id-ticket-span,
      > span {
        color: #4f4f4f;
      }
    }

    & > .info-aluno {
      & > .nome {
        // color: #595959;
        color: #333;
      }
      & > .campus,
      & > .risco {
        font-weight: 500;
      }
      & > .curso {
        // color: #4F4F4F;
        color: #595959;
        font-weight: 700;
      }
      & > .campus,
      & > .risco {
        color: #737373;
      }

      & > .risco {
        & > .baixo {
          color: #2f80ed;
        }
        & > .medio {
          color: #0cc27e;
        }
        & > .alto {
          color: #fb8115;
        }
        & > .critico {
          color: #eb5757;
        }
      }
    }
  }

  & .card-form {
    background-color: #fbfbfb;
    border: 1px solid #e0e0e0;
  }

  //pagination
  & .pagination-new > .pagination {
    margin: 0 auto !important;

    // & > .page-item .page-link{
    //     background-color: $branco;
    //     color: #459B9F;
    //     border-color: #DFE2E6;
    // }
  }

  //tabela
  & .table {
    & td {
      color: #000;
    }
    & th {
      color: #000 !important;

      & > div > svg {
        //stroke: #000 !important;
      }
    }
    & > tbody {
      tr:nth-of-type(odd) {
        background-color: #f6f6f6;
        border-bottom: 1px solid #e5e5e5;
      }
      tr {
        border-bottom: 1px solid #e5e5e5;
      }
      tr:first-child {
        border-top: 1px solid #e5e5e5;
      }
      tr:last-child {
        border: none;
      }
    }
  }

  & .tabela-mod {
    border: 1px solid #e0e0e0;

    & > thead {
      background-color: #0298db;

      & > tr {
        & > th {
          color: #fff !important;
        }
      }
    }
  }
  //Formulario
  & .informacao-card,
  & .informacao-card-form {
    color: #525252 !important;
  }
  & .select-label-position,
  & .select-questao,
  & .questao-text {
    color: #333 !important;
  }
  & .form-control {
    background: $branco;
    color: #4e4e56 !important;
    box-shadow: 0 4px 1px 0 rgba(212, 212, 212, 0.5);
    border: solid 1px #e0e0e0;

    &:focus {
      border: 1px solid rgba(53, 63, 81, 0.3) !important;
      box-shadow: 0 4px 1px rgba(53, 63, 81, 0.3);
    }
  }

  & .input-combo-box {
    & > div {
      border: 1px solid #e0e0e0;
      box-shadow: 0 4px 1px 0 rgba(212, 212, 212, 0.5);

      &:focus {
        border: 1px solid rgba(53, 63, 81, 0.3) !important;
        box-shadow: 0 4px 1px rgba(53, 63, 81, 0.3) !important;
      }

      & > * {
        color: #4e4e56 !important;
      }
    }
  }

  & .card-table {
    & .div-add-acao {
      background-color: #f5f5f5;
      border: 1px solid #e0e0e0;
      font-size: 20px;
      line-height: normal;
      font-weight: 400;
      color: #4f4f4f;
      padding: 20px 30px !important;

      & .btn-adicionar-questao {
        background-color: #35cc90;
        border: 1px solid #35cc90;
        margin-right: 5px;

        &:hover,
        &:focus {
          background-color: #0cc27e;
          border: 1px solid #0cc27e;
          box-shadow: none;
        }
      }
    }
    & .btn-salvar {
      background-color: #2f80ed;
      color: #fff;

      &:hover,
      &:focus {
        box-shadow: none;
        background-color: #0069d9;
      }
    }
    & .btn-cancelar {
      color: #535b6a;
      background-color: #fff;
      border: 1px solid #989da6;
      margin-right: 24px;

      &:hover,
      &:focus {
        box-shadow: none;
        background-color: #f3f3f3;
      }
    }
    & .btn-seleciona-template {
      color: #535b6a;
      background-color: #fff;
      border: 1px solid #989da6;
      //margin-right: 24px;

      &:hover,
      &:focus {
        box-shadow: none;
        background-color: #f3f3f3;
      }
    }
    & .btn-selecionados {
      color: #535b6a;
      background-color: #fff;
      border: 1px solid #989da6;
      //margin-right: 24px;

      &:hover,
      &:focus {
        box-shadow: none;
        background-color: #f3f3f3;
      }
    }
    & > div {
      & > .iniciar-atendimento {
        background-color: #35cc90;
        color: #fff;
        border: 1px solid #35cc90;

        &:focus,
        &:active {
          outline: none;
        }
        & > button {
          &:focus {
            box-shadow: none;
            background-color: #35cc90;
          }
        }
      }
      .dropdown-menu-atendimento {
        background: #fff;

        & > button {
          color: #333 !important;

          &:hover,
          &:focus {
            outline: none;
            box-shadow: none;
            background-color: #dcf5e9 !important;
          }
        }
      }
    }

    & > .acoes-ticket {
      background-color: #fbfbfb !important;
      border: 1px solid #e0e0e0 !important;

      & .acao {
        color: #1f1f1f;
      }
      & .descricao {
        color: #333;
      }

      & button.detalhes {
        padding: 3px 12px;
        border-radius: 3px;
        border: 1px solid #2f80ed;
        color: #2f80ed;
        background: #edf5fc;

        &:focus,
        &:active {
          outline: none;
        }
      }
    }

    .action-plan-mod {
      border: 1px solid #e0e0e0;
      background-color: #fbfbfb;
    }
  }

  //Contextos
  & .card-contexto {
    border: 1px solid #e0e0e0;
    background-color: #fff;

    & > .favoritar {
      //background-color: #eb5757;
      //color: #fff;
      //border: 1px solid #eb5757;

      &:hover,
      &:focus,
      &:active {
        border: 1px solid #eee;
        //background: #eee;
        color: #333;
        outline: none;
      }
    }

    & > .text-card-contexto {
      & > p {
        color: #333;
      }
    }
  }
  & .buscar-contexto {
    & > label {
      color: #333333 !important;
    }
    // & > input{}
  }

  & .add-alternativa {
    border-color: #2f80ed;
    color: #2f80ed;
    background-color: #edf5fc;
    margin-right: 10px;

    &:hover {
      background-color: #cce7ff !important;
      border-color: #2f80ed !important;
      color: #2f80ed !important;
    }

    &:active,
    &:focus {
      box-shadow: none !important;
      border-color: #2f80ed !important;
      color: #2f80ed !important;
      background-color: #edf5fc !important;
    }
  }

  & .width-gp-ctx {
    & .card-gp-ctx-academico {
      border: 1px solid #cccccc;
      // border: 1px solid #baceee;
      background-color: #fff;

      // & > svg{
      //     width: 100%;
      //     height: auto;
      // }
      & > p {
        color: #555;
      }
    }
  }

  //Detalhes Tickets
  & .sla {
    width: 10px;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
  }

  & .sla-atrasado {
    background-color: #ffd863;
  }
  & .sla-prazo {
    background-color: #2f80ed;
  }
  & .sla-concluido {
    background-color: #0cc27e;
  }

  //Resultado da Pesquisa
  & .total-enviados {
    background-image: linear-gradient(to top, #21e4ff, #2f80ed);
  }

  & .total-abertos {
    background-image: linear-gradient(to top, #fc9a64, #f95b75);
  }

  & .total-respondidos {
    background-image: linear-gradient(to top, #01eec3, #20c787);
  }

  & .total-timeline-pa {
    background-image: linear-gradient(to top, #d5caf2, #8360e0);
  }

  & .total-timeline-pq {
    background-image: linear-gradient(to top, #8dd5ab, #02a769);
  }

  & .total-timeline-tk {
    background-image: linear-gradient(to top, #ffb3ae, #ef3737);
  }

  & .total-timeline-cm {
    background-image: linear-gradient(to top, #8cb7f4, #3360d2);
  }

  & .total-enviados,
  & .total-abertos,
  & .total-respondidos,
  & .total-clicados,
  & .total-timeline-pa,
  & .total-timeline-pq,
  & .total-timeline-tk,
  & .total-timeline-cm {
    & > ul {
      & > li {
        > p {
          color: #fff;
        }
      }
    }
  }

  form {
    label {
      color: #525252;
    }
  }

  //ALUNOS
  .contact-search-box,
  .chat-fixed-search {
    background-color: #f5f4f4 !important;
    border-bottom: 1px solid #e0e0e0;
  }
  .contact-app-list-mails,
  .contact-app-mail-content,
  .chat-sidebar {
    border: 1px solid #e0e0e0;
  }
  .card-aluno-profile {
    background-image: linear-gradient(to top, #218eff, #2f80ed);
    ul {
      li {
        a {
          letter-spacing: 0.5px;
          font-weight: 500;
          color: #fff !important;
          border-color: #fff !important;
        }
      }
    }

    .nome-aluno {
      color: #fff;
    }
    .matricula-aluno {
      color: #eee !important;
    }
  }

  //CHAT
  .chat-application {
    .chat-app-window {
      border-right: 1px solid #e0e0e0;
    }
    .chat-name {
      border-right: 1px solid #e0e0e0;
      border-top: 1px solid #e0e0e0;
      border-bottom: 1px solid #e0e0e0;
      border-left: none;
    }
    .chat-fixed-search {
      border-right: none;
      width: 298px;
    }
    .aluno-chat {
      .list-group-item-heading {
        color: #1f1f1f;
      }
      .data {
        font-size: 13px !important;
        margin-bottom: 0px;
      }
    }

    .adicionar-conversa {
      background: #ff576b;
      border: 1px solid #ff576b;
      color: #fff;

      &:focus,
      &:hover {
        outline: none !important;
        background-color: #ff475d !important;
        border-color: #ff475d !important;
      }
    }
  }

  //Timeline
  .card-timeline {
    & .title {
      color: #fff;
    }
    & .tempo {
      color: #e8e8e8;
    }
  }
  .card-timeline-plano-acao {
    & > .header {
      background-color: #8360e0; //#ad97e6;
    }
  }
  .card-timeline-pesquisa {
    & > .header {
      background-color: #02a769;
    }
  }
  .card-timeline-ticket {
    & > .header {
      background-color: #ef3737;
    }
  }
  .card-timeline-comunicacao {
    & > .header {
      background-color: #3360d2;
    }
  }
  #timeline.timeline-center .timeline-line {
    background-color: #dfdfdf;
    border: 1px solid #dfdfdf;
  }

  .date-bg-timeline {
    background: #858585 0% 0% no-repeat padding-box;

    // &:hover,
    // &:focus,
    // &:active {
    //   background-color: #fae4ea !important;
    //   //border-color: #ff396f !important;
    //   box-shadow: none !important;
    //   color: #858585;
    // }
  }
  .text-pesquisar-aluno {
    h1 {
      color: #5a5a5a;
    }
    button {
      background-color: #2f80ed;
      color: #fff;

      &:hover,
      &:focus {
        background-color: #0069d9;
        transition: background-color 0.3s ease 0s, border-color 0.3s ease 0s;
      }
    }
  }
  .tabs-mod {
    .nav-item {
      > a {
        color: #333;
      }
      > a.active {
        color: #2f80ed;
        border-bottom: 2px solid #2f80ed;
      }
    }
  }
  .horizontal-timeline-mod {
    // .events{
    //     span:nth-child(2){
    //         background-color: #00ec94 !important;
    //         width: 804px !important;
    //     }
    // }
    ol {
      // li{
      //     &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4) {
      //         span{
      //             background-color: #00ec94 !important;
      //             border-color: #00ec94 !important;
      //         }
      //     }
      // }
      // li:nth-child(5){
      //     span{
      //         background-color: #fff !important;
      //         border:3px solid #00ec94 !important;
      //     }
      // }
      .present {
        color: #ff396e;
        font-weight: 600;
        transition: background-color 0.3s ease 0s, border-color 0.3s ease 0s;
      }
      li {
        span {
          &:hover,
          &:focus {
            background-color: #ffe3ea !important;
            // border-color: #FFE3EA !important;
          }
        }
      }
    }
  }
  .timeline-evento {
    .card-evento {
      border: 1px solid #e0e0e0;
      > p {
        color: #333;
      }
    }
    .plano-acao {
      background-color: #e6e2f1;
      border: 1px solid #c9c0e3;
    }
    .comunicacao {
      background-color: #e4edf8;
      border: 1px solid #b8d4f7;
    }
    .pesquisa {
      background-color: #e1f4ed;
      border: 1px solid #83e0bd;
    }
    .ticket {
      background-color: #f8e9e9;
      border: 1px solid #f9cdcd;
    }
    .descricao-plano-acao {
      background-color: #e6e2f180;
      border: 1px solid #c9c0e3;
    }
    .descricao-comunicacao {
      background-color: #f1f6fb;
      border: 1px solid #b8d4f7;
    }
    .descricao-pesquisa {
      background-color: #f0f9f6;
      border: 1px solid #83e0bd;
    }
    .descricao-ticket {
      background-color: #fbf4f4;
      border: 1px solid #f9cdcd;
    }

    .card-descricao-evento {
      > p {
        color: #4e4e56;
      }
    }
  }

  //Perfil
  .perfil-aluno {
    .titulo {
      color: #245fad;
      border-bottom: 1px solid #ececec;
    }
    h1 {
      color: #4e4e56;
    }
    .section-card-info-aluno {
      background: #eaeaea;
    }
    .card-inscricoes-ativas {
      > p:nth-child(1) {
        color: #1d3259;
      }
      > p:nth-child(2) {
        color: #484848;
      }
    }
    .table-timeline {
      border: 1px solid #e0e0e0;

      thead {
        background-color: #2f7fec;

        tr {
          th {
            color: #fff !important;
          }
        }
      }
      > tbody {
        tr {
          background-color: #fff;
        }
        tr:nth-of-type(odd) {
          background-color: #f6f6f6;
          border-bottom: 1px solid #e5e5e5;
        }
        tr:first-child {
          border-top: 1px solid #e5e5e5;
        }
      }
    }
  }

  .border-risco-baixo {
    background-color: #2f80ed;
  }
  .border-risco-medio {
    background-color: #0cc27e;
  }
  .border-risco-alto {
    background-color: #fb8115;
  }
  .border-risco-critico {
    background-color: #eb5757;
  }

  .baixo,
  .excelente {
    color: #2f80ed !important;
    font-weight: bold !important;
  }
  .medio,
  .bom {
    color: #0cc27e !important;
    font-weight: bold !important;
  }
  .alto,
  .ruim {
    color: #fb8115 !important;
    font-weight: bold !important;
  }
  .critico,
  .insatisfatorio {
    color: #eb5757 !important;
    font-weight: bold !important;
  }
}
//Email
.email-application {
  .email-app-list-mails {
    border: 1px solid #e0e0e0;
    border-left: none;
    //.email-search-box {
    //background-color: #f5f4f4 !important;
    //}
  }
  .email-app-sidebar-content {
    border: 1px solid #e0e0e0;
    border-right: none;
  }
  .email-search-box {
    border-bottom: 1px solid #e0e0e0;
  }
  .email-app-mail-content {
    border: 1px solid #e0e0e0;
    border-left: none;
  }
  @media (max-width: 1200px) {
    .email-app-content-area {
      border-left: 1px solid #e0e0e0;
    }
  }

  .enviar-email {
    .form-control:focus {
      border-color: red !important;
    }
  }
}

//Modal
.modal-header-ticket {
  background: #2f80ed;
  color: #fff;
}
.modal-body-ticket {
  & > .form-group {
    & > label {
      color: #000000;
    }
  }
}
.ul-modal-ticket {
  & > li {
    color: #535b6a;
    & > span {
      color: #4f4f4f;
    }
  }
}
.btn-cancelar-modal-ticket {
  border: 1px solid #f07371 !important;
  background: transparent !important;
  color: #f07371 !important;

  &:hover,
  &:focus {
    outline: none;
    background-color: #fff9f8 !important;
    color: #f07371 !important;
  }
}
.btn-continuar-modal-ticket {
  border: 1px solid #2f80ed;
  background: #2f80ed;

  &:hover,
  &:focus {
    box-shadow: none;
    outline: none;
    background: #338aff !important;
    border: 1px solid #338aff !important;
  }
}

//Modal do Analytics
.modal-analytics {
  & > .modal-content {
    background-color: #f9f9f9 !important;
  }

  .title-modal-analytics {
    & > .modal-title {
      color: #1f1f1f;
    }
  }
  .tabela {
    background: #eee;
    border: 1px solid #e0e0e0;
  }

  .funcionalidade {
    border: 1px solid #e0e0e0;
    background: #fff;

    & > .nome-funcionalidade {
      color: #404040;
    }
    & .texto-funcionalidade {
      color: #333;
    }
  }
}
//login
.card-login {
  h2 {
    color: #2d2d2d;
  }
  .label-login {
    color: #2d2d2d;
  }
  input {
    background-color: #fff !important;
    box-shadow: 0 4px 1px 0 rgba(212, 212, 212, 0.5);
    border: solid 1px #e0e0e0;

    &:focus {
      border: 1px solid rgba(53, 63, 81, 0.3) !important;
      box-shadow: 0 4px 1px rgba(53, 63, 81, 0.3) !important;
    }
  }
  .btn-entrar {
    background-color: #007aff;
    border-color: #007aff;

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 122, 255, 0.5) !important;
      background-color: #0074f0 !important;
      border-color: #0074f0 !important;
    }

    &:hover {
      background-color: #0074f0 !important;
      border-color: #0074f0 !important;
    }
  }
}

//ADD TICKET
.rdw-editor-wrapper {
  > div {
    border: 1px solid #e0e0e0 !important;
  }

  > .rdw-editor-toolbar {
    background: #f9f9f9;

    .rdw-option-wrapper {
      &:hover {
        background-color: #d6d5d5;
      }
    }
  }
}
